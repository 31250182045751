import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="The Throckmorton Customer Story - Rotaready"
          description="Find out how The Throckmorton managed to say goodbye to spreadsheets and eliminate mistakes in payroll, all whilst better deploying over three hours each week."
          url="customer-stories/hospitality-the-throckmorton"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Pub bar with assortment of ale taps"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Hospitality" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    style={{ height: '50px' }}
                    alt="The Throckmorton logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Find out how The Throckmorton managed to say goodbye to spreadsheets and eliminate mistakes in payroll, all whilst better deploying over three hours each week." />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="1" />
              <StatSubtitle text="site" />
            </StatItem>

            <StatItem>
              <StatHeadline text="30" />
              <StatSubtitle text="staff" />
            </StatItem>

            <StatItem>
              <StatHeadline text="436%" />
              <StatSubtitle text="ROI" />
            </StatItem>

            <StatItem>
              <StatHeadline text="3 hours" />
              <StatSubtitle text="better deployed each week" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="The Throckmorton" />
              </Content>

              <Content>
                <TextBlock text="Since owners Sally and Dave opened The Throckmorton in 2014, they have worked very closely with both their team and suppliers to ensure everything from the quality of food and drink, to the property standards and service are at an exceptional level. Ensuring both employee satisfaction, and that guests of the pub and inn receive the very best experience when visiting, is of great importance to the pair." />
              </Content>

              <Content>
                <TextBlock text="As the business developed Sally and Dave identified a number of factors that were holding them back on the level of service they were able to provide." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Laborious tasks and mistakes in payroll" />
              </Content>

              <Content>
                <TextBlock text="Building rotas and monitoring staff time using spreadsheets was causing both internal and customer facing issues." />
              </Content>

              <Content>
                <TextBlock>
                  Not only was this manual approach laborious and time consuming, the business had no way, other than personal intuition, of ensuring they had the perfect level of staff on hand to meet customer demand. Time and attendance data wasn't accurate enough to guarantee perfect <Link style="color:inherit;" to="/payroll">payroll</Link>. And sharing staff rotas via social media and printouts was causing a breakdown in communication across the business and confusion amongst employees when any changes were made.
                </TextBlock>
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Taking workforce management to the next level" />
              </Content>

              <Content>
                <TextBlock text="So, in 2017, Sally and Dave set about finding a solution that would help them solve the challenges they were facing without overcomplicating things for the business. Thanks to a recommendation, they turned to Rotaready for help." />
              </Content>

              <Content>
                <Blockquote attribution="Dave Atkinson - Owner">
                  Rotaready had everything we were looking for. A simple user interface, the means to improve communication across the business, flexible <Link to='/integrations' style="color:inherit;">integrations</Link>, outstanding support and access to in-depth attendance data made Rotaready far superior to anything we had seen before.
                </Blockquote>
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline1.childImageSharp.gatsbyImageData}
                alt="Pub menu on a table with wine glass"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Immediate results" />
              </Content>

              <Content>
                <TextBlock>
                  Since implementing Rotaready, The Throckmorton have seen a great improvement across multiple areas of the business. <Link style="color:inherit;" to="/payroll">Payroll</Link> is consistently accurate thanks to a more meticulous approach of monitoring staff time via wall-mounted tablets. Real- time visibility over costs has also helped the business to schedule staff time more efficiently and achieve the perfect balance between number of employees and customer demand.
                </TextBlock>
              </Content>

              <Content>
                <TextBlock text="Communication has also significantly improved across the business, thanks to the introduction of the Rotaready mobile app and real-time push notifications." />
              </Content>

              <Content>
                <Blockquote
                  text="Implementing Rotaready has helped us to improve employee communication drastically across the business. The team are loving the increased visibility and control the mobile app has given them over their upcoming shifts. It's easier than ever for them to request to swap shifts and to see who they will be working with next."
                  attribution="Dave Atkinson - Owner"
                />
              </Content>
            </Section>

            <Section>
              <PopQuote text="A combination of all of these factors plus the overall benefits of using Rotaready has helped the Throckmorton better deploy 3 and a half hours each week and achieve an ROI of over 435%." />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Integrating systems across the business" />
              </Content>

              <Content>
                <TextBlock>
                  Next on the agenda for The Throckmorton is enabling some of Rotaready's free <Link style="color:inherit;" to='/integrations'>integrations</Link>. Currently using Opentable as its reservation system and Quintek for EPOS, the pub & inn will be enabling these <Link style="color:inherit;" to='/integrations'>integrations</Link> during the course of 2020.
                </TextBlock>
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline2.childImageSharp.gatsbyImageData}
                alt="Waitress serving a table of guests in the pub"
              />
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_throckmorton"
                label="rotaready.com/customer-stories/hospitality-the-throckmorton"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-Throckmorton.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/header-throckmorton.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/logo-throckmorton.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 50, quality: 100)
      }
    }

    inline1: file(relativePath: { eq: "customer-stories/inline-throckmorton1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    inline2: file(relativePath: { eq: "customer-stories/inline-throckmorton2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
